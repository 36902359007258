import { ColumnDefinition } from '../models/column-definition.model';

// DASHBOARD TABLES
export const SI_NUMBER_TABLE_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'si_number',
    displayName: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.SI_NUMBER',
    style: 'width: 8rem',
  },
  {
    id: 11,
    field: 'risks',
    displayName: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.RISKS',
  },
  {
    id: 2,
    field: 'has_plantation_data',
    displayName: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.GEOMETRY_DATA',
    filter: true,
    filterName: 'has_plantation_data',
    style: 'max-width: 16rem',
  },
  // {
  //   id: 12,
  //   field: 'has_eudr_package',
  //   displayName: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.HAS_EUDR_PACKAGE',
  // },
  {
    id: 13,
    field: 'eudr_package',
    displayName: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.UPLOAD_EUDR_PACKAGE.EUDR_PACKAGE',
    style: 'width: 8rem',
  },
  {
    id: 3,
    field: 'contract_number',
    displayName: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.CONTRACT_NUMBER',
    style: 'width: 20rem',
  },
  {
    id: 4,
    field: 'buyer_ref_numb',
    displayName: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.REFERENCE_NUMBER',
  },
  {
    id: 5,
    field: 'po_number',
    displayName: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.PO_NUMBER',
  },
  {
    id: 6,
    field: 'counterparty',
    displayName: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.COUNTERPARTY',
    sortable: true,
    filter: true,
    filterName: 'counterparty',
    style: 'width: 20rem',
  },
  {
    id: 7,
    field: 'factory_code',
    displayName: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.SYMBOL',
    filter: true,
    filterName: 'factory_code',
  },
  {
    id: 8,
    field: 'grade_name',
    displayName: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.GRADE',
    filter: true,
    filterName: 'grade_name',
  },
  {
    id: 9,
    field: 'si_weight',
    displayName: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.VOLUME',
  },
  {
    id: 10,
    field: 'delivery_datetime',
    displayName: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.DELIVERY_MONTH',
    sortable: true,
    filter: true,
    style: 'width: 13rem',
    filterName: 'delivery_month',
  },
  {
    id: 13,
    field: 'action_column',
    displayName: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.ACTION_COLUMN',
    style: 'width: 8rem;',
  },
];

export const COUNTRY_TABLE_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'country',
    displayName: 'DASHBOARD.COUNTRY_TABLE_COLUMNS.COUNTRY',
    sortable: true,
    filter: true,
    editable: true,
  },
  {
    id: 2,
    field: 'counterparty',
    displayName: 'DASHBOARD.COUNTRY_TABLE_COLUMNS.COUNTERPARTY',
    sortable: true,
    filter: true,
    editable: true,
  },
  {
    id: 3,
    field: 'total_volume',
    displayName: 'DASHBOARD.COUNTRY_TABLE_COLUMNS.TOTAL_VOLUME',
    sortable: true,
    filter: true,
    editable: true,
  },
  {
    id: 4,
    field: 'traced_to_district',
    displayName: 'DASHBOARD.COUNTRY_TABLE_COLUMNS.TRACED_TO_DISTRICT',
    sortable: true,
    filter: true,
    editable: true,
  },
  {
    id: 5,
    field: 'traced_to_collection_point',
    displayName: 'DASHBOARD.COUNTRY_TABLE_COLUMNS.TRACED_TO_COLLECTION_POINT',
    sortable: true,
    filter: true,
    editable: true,
  },
  {
    id: 6,
    field: 'traced_to_plantation',
    displayName: 'DASHBOARD.COUNTRY_TABLE_COLUMNS.TRACED_TO_PLANTATION',
    sortable: true,
    filter: true,
    editable: true,
  },
  {
    id: 7,
    field: 'untraced',
    displayName: 'DASHBOARD.COUNTRY_TABLE_COLUMNS.UNTRACED',
    sortable: true,
    filter: true,
    editable: true,
  },
];

// LINK PLANTATION TABLES
export const PLANTATION_ID_TABLE_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'plantation_name',
    displayName: 'DASHBOARD.SI_LINKING.PLANTATION_ID_TABLE_COLUMNS.PLANTATION_NAME',
    sortable: true,
    editable: true,
    style: 'word-break: break-word;'
  },
  {
    id: 2,
    field: 'risks',
    displayName: 'DASHBOARD.SI_LINKING.PLANTATION_ID_TABLE_COLUMNS.RISKS',
    editable: false,
    style: 'width: 8rem;'
  },
  {
    id: 3,
    field: 'area_calculated',
    displayName: 'DASHBOARD.SI_LINKING.PLANTATION_ID_TABLE_COLUMNS.LAND_AREA',
    sortable: true,
    editable: true
  },
  {
    id: 4,
    field: 'country',
    displayName: 'DASHBOARD.SI_LINKING.PLANTATION_ID_TABLE_COLUMNS.COUNTRY',
    sortable: true
  },
  {
    id: 5,
    field: 'adm_1',
    displayName: 'DASHBOARD.SI_LINKING.PLANTATION_ID_TABLE_COLUMNS.PROVINCE_STATE',
    sortable: true
  },
  {
    id: 6,
    field: 'adm_2',
    displayName: 'DASHBOARD.SI_LINKING.PLANTATION_ID_TABLE_COLUMNS.DISTRICT',
    sortable: true
  },
  {
    id: 5,
    field: 'adm_3',
    displayName: 'DASHBOARD.SI_LINKING.PLANTATION_ID_TABLE_COLUMNS.SUB_DISTRICT',
    sortable: true
  },
  {
    id: 6,
    field: 'adm_4',
    displayName: 'DASHBOARD.SI_LINKING.PLANTATION_ID_TABLE_COLUMNS.VILLAGE',
    sortable: true
  },
  {
    id: 7,
    field: 'date_created',
    displayName: 'DASHBOARD.SI_LINKING.PLANTATION_ID_TABLE_COLUMNS.DATE_CREATED',
    sortable: true,
    editable: true
  },
  {
    id: 8,
    field: 'data_source',
    displayName: 'DASHBOARD.SI_LINKING.PLANTATION_ID_TABLE_COLUMNS.DATA_SOURCE',
    sortable: true,
    editable: false
  },
  {
    id: 9,
    field: 'field_team_code',
    displayName: 'DASHBOARD.SI_LINKING.PLANTATION_ID_TABLE_COLUMNS.FIELD_TEAM_CODE',
    sortable: false,
    editable: false
  },
];

export const TRANSACTION_ID_TABLE_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'id',
    displayName:
      'DASHBOARD.SI_LINKING.TRANSACTION_ID_TABLE_COLUMNS.TRANSACTION_ID',
  },
  {
    id: 2,
    field: 'sellerName',
    displayName:
      'DASHBOARD.SI_LINKING.TRANSACTION_ID_TABLE_COLUMNS.DEALER_NAME',
  },
  {
    id: 3,
    field: 'transactionDate',
    displayName:
      'DASHBOARD.SI_LINKING.TRANSACTION_ID_TABLE_COLUMNS.DATE_CREATED',
  },
  {
    id: 4,
    field: 'dryWeightKg',
    displayName:
      'DASHBOARD.SI_LINKING.TRANSACTION_ID_TABLE_COLUMNS.DRY_WEIGHT_KG',
  },
];

export const LINK_PLANTATION_TRANSACTION_SUMMARY_TABLE_COLUMNS: ColumnDefinition[] =
  [
    {
      id: 1,
      field: 'id',
      displayName: 'Transaction ID',
    },
    {
      id: 2,
      field: 'sellerName',
      displayName: 'Dealer Name',
    },
    {
      id: 3,
      field: 'transactionDate',
      displayName: 'Date Created',
    },
    {
      id: 4,
      field: 'dryWeightKg',
      displayName: 'Dry Weight (KG)',
    },
  ];

export const LINK_PLANTATION_SUMMARY_TABLE_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'polygon_data',
    displayName: 'RM/Polygon Data',
  },
  {
    id: 2,
    field: 'data_source',
    displayName: 'Data Source',
  },
  {
    id: 3,
    field: 'entry_date',
    displayName: 'RM Entry Date',
  },
  {
    id: 4,
    field: 'dry_weight',
    displayName: 'Dry Weight (KG)',
  },
  {
    id: 5,
    field: 'city',
    displayName: 'City',
  },
  {
    id: 6,
    field: 'district',
    displayName: 'District',
  },
  {
    id: 7,
    field: 'village',
    displayName: 'Village',
  },
  {
    id: 8,
    field: 'collection_point',
    displayName: 'Collection Point',
  },
  {
    id: 9,
    field: 'plantation',
    displayName: 'Plantation',
  },
];

// CREATE NEW SI TABLE COLUMNS
export const TRADE_CONFIRMATION_TABLE_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'contract_number',
    displayName:
      'DASHBOARD.CREATE_NEW_SI.TRADLE_CONFIRMATION_TABLE_COLUMNS.AG_CONFIRMATION',
  },
  {
    id: 2,
    field: 'type',
    displayName:
      'DASHBOARD.CREATE_NEW_SI.TRADLE_CONFIRMATION_TABLE_COLUMNS.TYPE',
  },
  {
    id: 3,
    field: 'counterparty',
    displayName:
      'DASHBOARD.CREATE_NEW_SI.TRADLE_CONFIRMATION_TABLE_COLUMNS.COUNTERPARTY',
  },
  {
    id: 4,
    field: 'volume',
    displayName:
      'DASHBOARD.CREATE_NEW_SI.TRADLE_CONFIRMATION_TABLE_COLUMNS.VOLUME_MT',
  },
  {
    id: 5,
    field: 'delivery_date',
    displayName:
      'DASHBOARD.CREATE_NEW_SI.TRADLE_CONFIRMATION_TABLE_COLUMNS.DATE',
  },
];


export const EU_IS_TABLE_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'si_number',
    displayName: 'DASHBOARD.EU_IS_TABLE_COLUMNS.SI_NUMBER'
  },
  {
    id: 2,
    field: 'status',
    displayName: 'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_STATUS',
    // filter: true,
    // filterName: 'status',
    // sortable:  true
  },
  {
    id: 3,
    field: 'dds_submission_euis',
    displayName: 'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION',
    // filter: true,
    // sortable:  true,
    // filterName: 'dds_submission_euis'
  },
  // {
  //   id: 12,
  //   field: 'geo_json',
  //   displayName: 'DASHBOARD.EU_IS_TABLE_COLUMNS.GEO_JSON_FILE',
  //   style: 'width: 4rem',
  // },
  {
    id: 4,
    field: 'dds_reference_number',
    displayName: 'DASHBOARD.EU_IS_TABLE_COLUMNS.REFERENCE_NUMBER'
  },
  {
    id: 5,
    field: 'submitted_on',
    displayName: 'DASHBOARD.EU_IS_TABLE_COLUMNS.SUBMISSION_DATE',
    // filter: true,
    // sortable:  true,
    // filterName: 'submitted_on'
  },
];

export const COUNTERPARTY_TABLE_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'name',
    displayName:
      'DASHBOARD.CREATE_NEW_SI.COUNTERPARTY_TABLE_COLUMNS.COUNTERPARTY',
    style: 'width: 50%;',
  },
  // {
  //   id: 2,
  //   field: 'email_address',
  //   displayName:
  //     'DASHBOARD.CREATE_NEW_SI.COUNTERPARTY_TABLE_COLUMNS.EMAIL_ADDRESS',
  //   style: 'width: 50%;',
  // },
];
