<ng-container *ngIf="
    !(eventStateService.isDownloadDdrLoading$ | async);
    else downloadInProgress
  ">
  <div [formGroup]="ddsFormGroup">
    <!-- <div *ngIf="isFinalPage" class="flex flex-column my-4">
    <div class="qr-placeholder"></div>
    <qrcode
      [qrdata]="qrData"
      [width]="100"
      [errorCorrectionLevel]="'M'"
    ></qrcode>
    <span>Scan to verify</span>
  </div> -->

    <div class="flex font-bold px-4 my-4">
      <!-- <div class="flex align-items-center">
        <input type="checkbox" formControlName="includeInEudrPackage" class="mr-2">
        <label class="font-semibold">Include in EUDR package</label>
      </div> -->
    </div>

    <!-- summary -->
    <div class="flex px-4 flex-column">
      <div class="header-background">
        <span>{{ "DASHBOARD.DDR.SUMMARY" | transloco }}</span>
      </div>
      <div class="flex mt-3">
        <div class="grid w-full">
          <div class="col-4">
            <div class="flex flex-column">
              <span class="text-sm">{{ "DASHBOARD.DDR.TOTAL_PLANTATIONS" | transloco }}:</span>
              <span class="text-4xl font-bold">{{ totalPlantations }}</span>
            </div>
          </div>
          <div class="col-4">
            <div class="flex flex-column">
              <span class="text-sm">{{ "DASHBOARD.DDR.TOTAL_HECTARAGE" | transloco }}:</span>
              <span class="text-4xl font-bold">{{ totalHectarageFormatted }} </span>
            </div>
          </div>
          <div class="col-4">
            <div class="flex flex-column">
              <span class="text-sm">{{ "DASHBOARD.DDR.TOTAL_VOLUME" | transloco }}:</span>
              <span class="text-4xl font-bold">{{ totalVolumeMt | number : "1.2-2" }} MT</span>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-nogutter align-items-center">
        <div class="col-2 flex align-items-center">
          <span class="material-symbols-outlined text-sm" style="color: #89a9fc">
            layers
          </span>
          <span class="text-sm">{{ "DASHBOARD.DDR.POLYGONS" | transloco }}:</span>
        </div>
        <div class="col-6 flex justify-content-start">
          <span class="text-sm font-bold"> {{ totalPolygons }} </span>
        </div>
      </div>
      <div class="grid grid-nogutter align-items-center">
        <div class="col-2 flex align-items-center">
          <span class="material-symbols-outlined text-sm" style="color: #89a9fc">
            location_on
          </span>
          <span class="text-sm">{{ "DASHBOARD.DDR.GPS_POINTS" | transloco }}:</span>
        </div>
        <div class="col-6 flex justify-content-start">
          <span class="text-sm font-bold"> {{ totalPoints }}</span>
        </div>
      </div>
      <div class="sub-item">
        <span class="text-sm">{{
          "DASHBOARD.DDR.DATA_COLLECTED_IN_ACCORDANCE" | transloco
          }}</span>
        <p-checkbox *ngIf="!isFinalPage" class="text-sm" [binary]="true"
          formControlName="isSummaryItem1Checked"></p-checkbox>
        <i *ngIf="isFinalPage && isSummaryItem1Checked.value" class="pi pi-check"></i>
      </div>
      <div class="sub-item">
        <span class="text-sm">{{
          "DASHBOARD.DDR.PRODUCE_IN_ACCORDANCE" | transloco
          }}</span>
        <p-checkbox *ngIf="!isFinalPage" class="text-sm" [binary]="true"
          formControlName="isSummaryItem2Checked"></p-checkbox>
        <i *ngIf="isFinalPage && isSummaryItem2Checked.value" class="pi pi-check"></i>
      </div>
      <!-- <div class="sub-item-last">
      <span class="text-sm">Deforestation-free</span>
      <span class="font-bold text-sm">100%</span>
    </div> -->
    </div>
    <!-- end summary -->

    <!-- SI and country of origin -->
    <div class="flex mt-5 px-4 flex-column">
      <div class="header-background">
        <span>{{
          "DASHBOARD.DDR.SHIPPING_INSTRUCTION_AND_COUNTRY_OF_ORIGIN" | transloco
          }}</span>
      </div>
      <div *ngFor="let si of selectedSis" class="sub-item">
        <span class="text-sm">{{ si.si_number }}</span>
        <ng-container *ngIf="si.summary?.countries?.length; else noCountry">
          <div class="flex flex-column">
            <span *ngFor="let country of si.summary?.countries; last as last" class="font-bold text-right text-sm">{{
              country }}
            </span>
          </div>
        </ng-container>

        <ng-template #noCountry>
          <span class="mr-2 font-bold text-sm">-</span>
        </ng-template>
      </div>
    </div>
    <!-- end  SI and country of origin -->

    <!-- overall risk overlaps -->

    <div class="flex mt-5 px-4 flex-column">
      <div class="header-background">
        <span>{{ "DASHBOARD.DDR.OVERALL_RISK_OVERLAPS" | transloco }}</span>
      </div>

      <ng-container *ngFor="let riskOverlap of riskOverlaps">
        <div class="sub-item">
          <div class="flex flex-column w-full">
            <div class="flex align-items-center mb-2">
              <app-risk-icon [marginClass]="'mr-2'" [riskType]="riskOverlap.riskType"></app-risk-icon>
              <span class="text-sm">{{ riskOverlap.label }}</span>
            </div>
            <ng-container *ngFor="let item of riskOverlap.detailList">
              <div class="ml-5-2 my-1 flex justify-content-between">
                <span class="font-bold text-sm">{{ item.si_numb }} </span>
                <span class="font-bold text-sm">{{ item.risks[riskOverlap.riskType].overlapAreaPerc }}%
                </span>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <!-- end overall risk overlaps -->
    </div>

    <!-- operator information -->
    <div class="flex mt-5 px-4 flex-column">
      <div class="header-background">
        <span>{{ "DASHBOARD.DDR.OPERATOR_INFORMATION" | transloco }}</span>
      </div>
      <div class="sub-item">
        <span>{{
          "DASHBOARD.DDR.OPERATORS_NAME" | transloco
          }}</span>
        <input *ngIf="!isFinalPage" type="text" pInputText formControlName="factoryName" />
        <span *ngIf="isFinalPage">{{factoryName.value}}</span>
      </div>
      <div class="sub-item-last">
        <span>{{
          "DASHBOARD.DDR.OPERATORS_ADDRESS" | transloco
          }}</span>
        <input *ngIf="!isFinalPage" type="text" pInputText formControlName="factoryAddress" />
        <span *ngIf="isFinalPage">{{factoryAddress.value}}</span>
      </div>
      <!-- end operator information -->
    </div>
    <!-- commodity information -->
    <div class="flex mt-2 px-4 flex-column">
      <div class="header-background">
        <span>{{ "DASHBOARD.DDR.COMMODITY_INFORMATION" | transloco }}</span>
      </div>

      <!-- <div class="sub-item">
        <span>{{ "DASHBOARD.DDR.EORI" | transloco }}</span>
        <input *ngIf="!isFinalPage" type="text" pInputText formControlName="eoriNumber" />
        <span *ngIf="isFinalPage" class="font-bold text-sm">{{
          eoriNumber.value
          }}</span>
      </div> -->
      <div class="sub-item">
        <span>{{ "DASHBOARD.DDR.HARMONISED_SYSTEM_CODE" | transloco }}</span>
        <p-multiSelect
          *ngIf="!isFinalPage"
          [options]="harmonisedSystemOptions"
          formControlName="harmonisedSystemCodes"
          placeholder="Select HS Codes"
          styleClass="custom-dropdown"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          (onChange)="onHarmonisedSystemCodesChange($event)"
          >
        </p-multiSelect>
        <span *ngIf="isFinalPage" class="hscode-container">{{harmonisedSystemCodes.value}}</span>
      </div>
      <div class="sub-item">
        <span>{{
          "DASHBOARD.DDR.TRADE_NAME" | transloco
          }}</span>
        <span class="font-bold text-sm">{{ tradeName.value }}</span>
      </div>
      <div class="sub-item">
        <span>{{
          "DASHBOARD.DDR.SCIENTIFIC_NAME" | transloco
          }}</span>
        <span class="font-bold text-sm">{{ scientificName.value }}</span>
      </div>
      <div class="sub-item-last">
        <span>{{
          "DASHBOARD.DDR.QUANTITY_KG" | transloco
          }}</span>
        <span class="font-bold text-sm">{{ quantity.value }}</span>
      </div>
      <!-- end commodity information -->
    </div>

    <!-- disclaimer -->
    <div *ngIf="isFinalPage">
      <div class="flex mt-2 px-4 flex-column">
        <div class="header-background">
          <span>{{ "DASHBOARD.DDR.ACKNOWLEDGEMENT" | transloco }}</span>
        </div>
        <div class="mt-2">
          <span class="text-sm">{{
            "DASHBOARD.DDR.DISCLAIMER_PARAGRAPH" | transloco
            }}</span>
        </div>
      </div>
      <div class="grid px-4 mt-4 row-gap-4">
        <div class="col-6 flex flex-column">
          <span class="text-sm pb-2 font-semibold">{{ "DASHBOARD.DDR.NAME" | transloco }}</span>
          <input type="text" pInputText placeholder="Enter Processor Name" formControlName="processorName" />
        </div>
        <div class="col-6 flex flex-column">
          <span class="text-sm pb-2 font-semibold">{{ "DASHBOARD.DDR.DATE" | transloco }}</span>
          <input type="date" pInputText formControlName="processDate" />
        </div>
      </div>
      <div class="flex mt-5 px-4 flex-column">
        <div class="mt-2">
          <span class="text-sm">{{
            "DASHBOARD.DDR.SHIPMENTS_DISCLAIMER" | transloco
            }}</span>
        </div>
      </div>

      <div class="mt-5 px-4 flex justify-content-start  ">
        <img src="../../../assets/images/dds-logo.png" alt="agridence-logo" />
      </div>
    </div>
    <!-- end disclaimer -->

    <div class="action-section">
      <p-button *ngIf="isFinalPage" class="mr-4 mt-4" [outlined]="true" (onClick)="onBackClicked()">{{
        "DASHBOARD.DDR.BACK" | transloco }}</p-button>
      <p-button [disabled]="ddsFormGroup.invalid" (onClick)="onConfirmClicked()">{{ primaryActionText | transloco
        }}</p-button>
    </div>
  </div>
</ng-container>

<ng-template #downloadInProgress>
  <div class="flex justify-content-center align-items-center" style="height: 35rem">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>