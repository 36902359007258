export enum TraceabilityTableTabsEnum {
  TRACEABILITY = 0,
  EU_IS = 1
}

export enum DDSModalModesEnum {
  SUBMIT = 'SUBMIT',
  AMEND = 'AMEND',
  WITHDRAW = 'WITHDRAW',
  VIEW = 'VIEW',
  CONFIRM_SUBMISSION = 'CONFIRM_SUBMISSION'
}