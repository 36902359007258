import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DDSModalModesEnum } from 'src/app/enums/traceability-table-tabs.enum';
import { DdsSubmissionFormModalComponent } from '../dds-submission-form-modal.component';
import { DDSSubmissionPayload } from 'src/app/models/dds-submission-payload.model';
import { EuisService } from 'src/app/services/data-service/euis.service';
import { UtilityService } from 'src/app/services/utility.service';
import { FormsModule } from '@angular/forms';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import { Subject} from 'rxjs';

@Component({
  selector: 'app-dds-submission-confirmation-modal',
  standalone: true,
  imports: [CommonModule, PrimengExportsModule, TranslocoPipe, FormsModule],
  templateUrl: './dds-submission-confirmation-modal.component.html',
  styleUrls: ['./dds-submission-confirmation-modal.component.scss']
})
export class DdsSubmissionConfirmationModalComponent {
  ddsModalModelsEnum = DDSModalModesEnum;
  mode: DDSModalModesEnum = this.ddsModalModelsEnum.WITHDRAW;
  dialogData: any;
  ddsSubmissionForm: any;
  prefill = false;
  isLoading: boolean = false;
  payload!: DDSSubmissionPayload;
  confidential_geolocation = false;
  destroyed$ = new Subject<void>();
  ddsStatus: any;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    private translocoService: TranslocoService,
    private utilityService: UtilityService,
    private euisService: EuisService,
    private traceabilityStateService: TraceabilityStateService,
  ) {
    this.mode = this.dialogConfig.data.mode ? this.dialogConfig.data.mode : this.ddsModalModelsEnum.SUBMIT;
  }

  ngOnInit() {
    this.dialogData = this.dialogConfig.data;
    this.ddsStatus = this.dialogConfig.data.rowData.status;
    this.ddsSubmissionForm = this.dialogData.form?.value;
    if (this.mode === this.ddsModalModelsEnum.SUBMIT && this.prefill) {
      this.dialogService.open(DdsSubmissionFormModalComponent, {
        width: '60%',
        header: '',
        data: { rowData: this.dialogData.rowData, editable: this.dialogData.editable },
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openDdsSubmissionForm(editable: boolean, prefill: boolean, mode: DDSModalModesEnum, form?: any,) {
    this.dialogService.open(DdsSubmissionFormModalComponent, {
      width: '60%',
      header: `${this.translocoService.translate(
        mode === DDSModalModesEnum.VIEW ? 'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.DDS_VIEW_SUBMISSION_FORM_HEADER' : 'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.DDS_CREATE_SUBMISSION_FORM_HEADER'
      )}`,
      data: { editable, form, mode, rowData: this.dialogData.rowData },
    });
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close();
  }

  createDDS() {
    this.constructPayload();
    this.isLoading = true;
    const override = this.ddsStatus === 'Failed' ? true : false;
    this.euisService.submitDDSToEuIs(this.dialogData.rowData.sidetail_id, this.payload, override)
    .subscribe({
      next: (response) => {
        this.isLoading = false;
        this.utilityService.emitToast({
          message: 'DDS submission successfully created.',
          isSuccess: true,
        });
        
        this.traceabilityStateService.getSiPage(this.dialogConfig.data.tableParams);
        this.dialogRef.close();
      },
      error: (error) => {
        this.isLoading = false;
        this.utilityService.emitToast({
          message: 'Failed to create DDS submission. Please try again.',
          isSuccess: false,
        });
        console.error('Error submitting DDS:', error);
      }
    });
  }

  withdrawDDS() {
    //insert backend integration of withdrawing DDS
    this.closeModal();
    this.utilityService.emitToast({
      message: 'DDS submission for SI/0123/0010 has been withdrawn successfully.',
      isSuccess: true,
    });
  }

  constructPayload() {
    const username = sessionStorage.getItem('eu_is_user') || '';
    const authToken = sessionStorage.getItem('eu_is_secret_key') || '';
    this.payload = {
      username,
      authtoken: authToken,
      company_internal_reference_number: this.ddsSubmissionForm.companyInternalRefNo,
      country_of_activity: this.ddsSubmissionForm.operatorISOCode,
      description_of_goods: this.ddsSubmissionForm.goodsDescription,
      volume_of_goods: this.ddsSubmissionForm.volume,
      weight_of_goods: this.ddsSubmissionForm.netMass,
      number_of_units_of_goods: this.ddsSubmissionForm.supplementaryUnits,
      hscode: this.ddsSubmissionForm.hsCode,
      producer_name: this.ddsSubmissionForm.producerName,
      geojson: this.dialogData.geoJsonData,
      activityType: this.ddsSubmissionForm.activity,
      is_confidential: this.confidential_geolocation
    }
  }

  updateDDS(){
    this.isLoading = true;
    this.constructPayload();
    this.euisService.submitDDSToEuIs(this.dialogData.rowData.sidetail_id, this.payload, true)
    .subscribe({
      next: (response) => {
        this.isLoading = false;
        this.utilityService.emitToast({
          message: 'DDS submission successfully ammended.',
          isSuccess: true,
        });
        this.dialogRef.close();
      },
      error: (error) => {
        this.isLoading = false;
        this.utilityService.emitToast({
          message: 'Failed to ammend DDS submission. Please try again.',
          isSuccess: false,
        });
        console.error('Error ammending DDS:', error);
      }
    });
  }
}
