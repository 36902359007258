<ng-container *ngIf="!(eventStateService.isDownloadDdrLoading$ | async); else downloadInProgress">
    <div [formGroup]="ddsFormGroup">
        <div class="flex flex-column mx-4">
            <div class="header-background font-semibold">
                <span *ngIf="mode === ddsModalModesEnum.SUBMIT">{{
                    "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.DDS_CREATE_SUBMISSION_FORM_SUBHEADER" | transloco
                    }}</span>
                <span *ngIf="mode === ddsModalModesEnum.VIEW">{{
                    "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.DDS_VIEW_SUBMISSION_FORM_SUBHEADER" | transloco
                    }}</span>
                <span *ngIf="mode === ddsModalModesEnum.AMEND">{{
                    "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.DDS_AMEND_SUBMISSION_FORM_SUBHEADER" | transloco
                    }}</span>
            </div>
            <div class="py-4">
                <!-- <div *ngIf="editable && mode === ddsModalModesEnum.SUBMIT"
                    class="last-item pb-4 flex flex-row align-items-center">
                    <span class="font-semibold">{{
                        "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.DDS_SUBMISSION_PREFILL_FORM" | transloco
                        }}</span>
                    <p-inputSwitch class="pl-2" formControlName="prefill" />
                </div> -->
                <!-- Company Internal Reference Number -->
                <div class="last-item col-12 pb-4 px-0 flex flex-row align-items-center">
                    <div class="flex flex-column col-6">
                        <span class="font-semibold" [style.opacity]="mode === ddsModalModesEnum.AMEND ? '0.5' : '1'">{{
                            "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.COMPANY_INTERNAL_REF_NO" | transloco
                            }}</span>
                        <ng-container *ngIf="editable; else displayCompanyInternalRefNo">
                            <input type="text" class="mt-2" pInputText formControlName="companyInternalRefNo"
                               [ngClass]="{
                                    'red-border': ddsFormGroup.get('companyInternalRefNo')?.invalid && ddsFormGroup.get('companyInternalRefNo')?.touched,
                                    'disabled': mode === ddsModalModesEnum.AMEND
                                }" [style.opacity]="mode === ddsModalModesEnum.AMEND ? '0.5' : '1'" />
                        </ng-container>
                        <ng-template #displayCompanyInternalRefNo>
                            <span>{{ ddsFormGroup.get('companyInternalRefNo')?.value || '-' }}</span>
                        </ng-template>
                    </div>
                    <div class="flex flex-column col-6">
                        <span class="font-semibold">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.ACTIVITY" |
                            transloco }}<span class="required pl-1">*</span></span>
                        <ng-container *ngIf="editable; else displayActivity">
                            <p-dropdown class="pt-2 dds-dropdown" [options]="activityOptions" formControlName="activity"
                               >
                            </p-dropdown>
                        </ng-container>
                        <ng-template #displayActivity>
                            <span>{{ ddsFormGroup.get('activity')?.value || '-' }}</span>
                        </ng-template>
                    </div>
                </div>
                <!--End of Company Internal Reference Number -->

                <!-- Operator Name and Address -->
                <div class="col-12 flex flex-row align-items-center">
                    <span class="py-2 font-bold text-xl font-semibold">
                        {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.OPERATOR_INFORMATION_HEADER" | transloco }}
                    </span>
                    <span class="required pl-1">*</span>
                </div>
                <div class="col-12 flex flex-column">
                    <span class="font-semibold">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.OPERATOR_NAME" | transloco }}</span>
                    <ng-container *ngIf="editable; else displayOperatorName">
                        <input type="text" class="search-bar mt-2" pInputText formControlName="operatorName"
                            [ngClass]="{'red-border': ddsFormGroup.get('operatorName')?.invalid && ddsFormGroup.get('operatorName')?.touched}" />
                    </ng-container>
                    <ng-template #displayOperatorName>
                        <span>{{ ddsFormGroup.get('operatorName')?.value || '-' }}</span>
                    </ng-template>
                </div>
                <div class="last-item col-12 pb-4 px-0 flex flex-row align-items-center">
                    <div class="flex flex-column col-6">
                        <span class="font-semibold">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.OPERATOR_COUNTRY" | transloco }}</span>
                        <ng-container *ngIf="editable; else displayOperatorCountry">
                            <p-dropdown class="pt-2 dds-dropdown" [options]="euCountryOptions"
                                formControlName="operatorCountry"
                                (onChange)="onCountryChange($event)"
                                placeholder="{{ 'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.SELECT_COUNTRY' | transloco }}"
                                ></p-dropdown>
                        </ng-container>
                        <ng-template #displayOperatorCountry>
                            <span>{{ ddsFormGroup.get('operatorCountry')?.value || '-'  }}</span>
                        </ng-template>
                    </div>
                    <div class="flex flex-column col-6">
                        <span class="font-semibold">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.ISO_CODE" | transloco }}</span>
                        <ng-container *ngIf="editable; else displayISOCode">
                            <input type="text" class="search-bar mt-2" pInputText formControlName="operatorISOCode" readonly />
                        </ng-container>
                        <ng-template #displayISOCode>
                            <span>{{ ddsFormGroup.get('operatorISOCode')?.value || '-' }}</span>
                        </ng-template>
                    </div>
                </div>
                <!--End Operator Name and Address -->

                <!-- Place of Activity -->
                <div class="col-12 flex flex-row align-items-center">
                    <span class="py-2 font-bold text-xl font-semibold">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.PLACE_OF_ACTIVITY" | transloco }} </span>
                    <span class="required pl-1">*</span>
                </div>
                <div class="col-12 px-0 pb-0 flex flex-row align-items-center">
                    <div class="flex flex-column col-6" *ngIf="activity === 'EXPORT'">
                        <span class="font-semibold">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.COUNTRY_OF_EXIT" | transloco }}</span>
                        <ng-container *ngIf="editable; else displayActivityCountry">
                            <p-dropdown class="pt-2 dds-dropdown" [options]="euCountryOptions"
                                formControlName="exitCountry"
                                placeholder="{{ 'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.SELECT_COUNTRY' | transloco }}"
                                ></p-dropdown>
                        </ng-container>
                        <ng-template #displayActivityCountry>
                            <span>{{ ddsFormGroup.get('activityCountry')?.value || '-' }}</span>
                        </ng-template>
                    </div>
                    <div class="flex flex-column col-6" *ngIf="activity === 'IMPORT'">
                        <span class="font-semibold">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.COUNTRY_OF_ACTIVITY" | transloco }}</span>
                        <ng-container *ngIf="editable; else displayActivityCountry">
                            <p-dropdown class="pt-2 dds-dropdown" [options]="euCountryOptions"
                                formControlName="activityCountry"
                                placeholder="{{ 'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.SELECT_COUNTRY' | transloco }}"
                                ></p-dropdown>
                        </ng-container>
                        <ng-template #displayActivityCountry>
                            <span>{{ ddsFormGroup.get('activityCountry')?.value || '-' }}</span>
                        </ng-template>
                    </div>
                    <div class="flex flex-column col-6" *ngIf="activity === 'IMPORT'">
                        <span class="font-semibold">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.COUNTRY_OF_ENTRY" | transloco }}</span>
                        <ng-container *ngIf="editable; else displayEntryCountry">
                            <p-dropdown class="pt-2 dds-dropdown" [options]="euCountryOptions"
                                formControlName="entryCountry"
                                placeholder="{{ 'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.SELECT_COUNTRY' | transloco }}"
                                ></p-dropdown>
                        </ng-container>
                        <ng-template #displayEntryCountry>
                            <span>{{ ddsFormGroup.get('entryCountry')?.value || '-' }}</span>
                        </ng-template>
                    </div>
                </div>
                <div class="last-item col-12 pb-4 pt-0 px-0 flex flex-row align-items-center">
                    <div *ngIf="editable" class="flex flex-column col-6">
                        <p-button [outlined]="true" class="copy-btn" (onClick)="copyOperatorCountry()">
                            {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.COPY_OPERATOR_COUNTRY" | transloco }}
                        </p-button>
                    </div>
                </div>
                <!-- End of Place of Activity -->

                <!-- Additional Information -->
                <!-- <div class="col-12 flex flex-row align-items-center">
                    <span class="py-2 font-bold text-xl font-semibold">Additional Information</span>
                </div>
                <div class="last-item col-12 pb-4 flex flex-column">
                    <span class="font-semibold">Additional Information</span>
                    <ng-container *ngIf="editable; else displayAdditionalInfo">
                        <input type="text" class="search-bar mt-2" pInputText formControlName="additionalInfo" />
                    </ng-container>
                    <ng-template #displayAdditionalInfo>
                        <span>{{ ddsFormGroup.get('additionalInfo')?.value || '-' }}</span>
                    </ng-template>
                </div> -->
                <!-- End of Additional Information -->

                <!-- Product -->
                <div class="col-12 flex flex-row align-items-center">
                    <span class="py-2 font-bold text-xl font-semibold">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.PRODUCT_HEADER" | transloco }}</span>
                    <span class="required pl-1">*</span>
                </div>
                <div class="col-12 pb-4 px-0 flex flex-row align-items-center">
                    <div class="flex flex-column col-6">
                        <span class="font-semibold">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.HS_CODE" | transloco }}</span>
                        <ng-container *ngIf="editable; else displayHSCode">
                            <p-dropdown
                                class="pt-2 dds-dropdown hscode-dropdown"
                                [options]="hsCodesOptions"
                                formControlName="hsCode"
                                [filter]="true"
                                filterPlaceholder="Search"
                                optionLabel="label"
                                optionValue="value"
                            >
                            </p-dropdown>
                        </ng-container>
                        <ng-template #displayHSCode>
                            <span>{{ ddsFormGroup.get('hsCode')?.value || '-'  }}</span>
                        </ng-template>
                    </div>
                    <div class="flex flex-column col-6">
                        <span class="font-semibold">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.DESCRIPTION_OF_GOODS" | transloco }} <span class="required pl-1">*</span></span>
                        <ng-container *ngIf="editable; else displayGoodsDescription">
                            <input type="text" class="search-bar mt-2" pInputText formControlName="goodsDescription"
                                [ngClass]="{'red-border': ddsFormGroup.get('goodsDescription')?.invalid && ddsFormGroup.get('goodsDescription')?.touched}" />
                        </ng-container>
                        <ng-template #displayGoodsDescription>
                            <span>{{ ddsFormGroup.get('goodsDescription')?.value || '-' }}</span>
                        </ng-template>
                    </div>
                </div>
                <!-- <div class="col-12 pb-4 px-0 flex flex-row align-items-center">
                    <div class="flex flex-column col-6">
                        <span class="font-semibold"
                            [style.opacity]="mode === ddsModalModesEnum.AMEND ? '0.5' : '1'">Scientific Name</span>
                        <ng-container *ngIf="editable; else displayScientificName">
                            <input type="text" class="search-bar mt-2" pInputText formControlName="scientificName"
                                [ngClass]="{
                                    'red-border': ddsFormGroup.get('scientificName')?.invalid && ddsFormGroup.get('scientificName')?.touched,
                                    'disabled': mode === ddsModalModesEnum.AMEND
                                }" [style.opacity]="mode === ddsModalModesEnum.AMEND ? '0.5' : '1'" />
                        </ng-container>
                        <ng-template #displayScientificName>
                            <span>{{ ddsFormGroup.get('scientificName')?.value || '-' }}</span>
                        </ng-template>
                    </div>
                    <div class="flex flex-column col-6">
                        <span class="font-semibold">Common Name</span>
                        <ng-container *ngIf="editable; else displayCommonName">
                            <input type="text" class="search-bar mt-2" pInputText formControlName="commonName"
                                [ngClass]="{'red-border': ddsFormGroup.get('commonName')?.invalid && ddsFormGroup.get('commonName')?.touched}" />
                        </ng-container>
                        <ng-template #displayCommonName>
                            <span>{{ ddsFormGroup.get('commonName')?.value || '-' }}</span>
                        </ng-template>
                    </div>
                </div> -->
                <div class="last-item col-12 pb-4 px-0 flex flex-row align-items-center">
                    <div class="flex flex-column col-6">
                        <span class="font-semibold" [style.opacity]="mode === ddsModalModesEnum.AMEND ? '0.5' : '1'">
                            {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.NET_MASS" | transloco }}
                        </span>
                            <span>{{ ddsFormGroup.get('netMass')?.value || '-' }}</span>
                    </div>
                    <!-- <div class="flex flex-column col-3">
                        <span class="font-semibold"
                            [style.opacity]="mode === ddsModalModesEnum.AMEND ? '0.5' : '1'">Volume (m3)</span>
                        <ng-container *ngIf="editable; else displayVolume">
                            <input type="number" class="search-bar mt-2" pInputText formControlName="volume"
                                [ngClass]="{
                                    'red-border': ddsFormGroup.get('volume')?.invalid && ddsFormGroup.get('volume')?.touched,
                                    'disabled': mode === ddsModalModesEnum.AMEND
                                }" [style.opacity]="mode === ddsModalModesEnum.AMEND ? '0.5' : '1'" />
                        </ng-container>
                        <ng-template #displayVolume>
                            <span>{{ ddsFormGroup.get('volume')?.value || '-' }}</span>
                        </ng-template>
                    </div>
                    <div class="flex flex-column col-3">
                        <span class="font-semibold">Supplementary Units</span>
                        <ng-container *ngIf="editable; else displaySupplementaryUnits">
                            <input type="number" class="search-bar mt-2" pInputText formControlName="supplementaryUnits"
                                [ngClass]="{'red-border': ddsFormGroup.get('supplementaryUnits')?.invalid && ddsFormGroup.get('supplementaryUnits')?.touched}" />
                        </ng-container>
                        <ng-template #displaySupplementaryUnits>
                            <span>{{ ddsFormGroup.get('supplementaryUnits')?.value || '-' }}</span>
                        </ng-template>
                    </div> -->
                    <div class="flex flex-column col-6">
                        <span class="font-semibold"
                            [style.opacity]="mode === ddsModalModesEnum.AMEND ? '0.5' : '1'">
                            {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.AREA" | transloco }}
                        </span>
                            <span>{{ ddsFormGroup.get('area')?.value || '-' }}</span>
                    </div>
                </div>
                <!-- End of Product -->

                <!-- Production Place -->
                <div class="col-12 flex flex-row align-items-center">
                    <span class="py-2 font-bold text-xl font-semibold">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.PRODUCTION_PLACE" | transloco }}</span>
                </div>
                <div class="col-12 pb-4 px-0">
                    <p-table [value]="groupedGeoData">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.PRODUCER_NAME" | transloco }}</th>
                                <th>{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.PRODUCER_COUNTRY" | transloco }} </th>
                                <th>{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.TOTAL_AREA" | transloco }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-producer>
                            <tr>
                                <td>
                                    <span class="font-semibold" [style.opacity]="mode === ddsModalModesEnum.AMEND ? '0.5' : '1'">
                                        {{ producer.ProducerName || '-' }}
                                    </span>
                                </td>
                                <td>
                                    <span class="font-semibold" [style.opacity]="mode === ddsModalModesEnum.AMEND ? '0.5' : '1'">
                                        {{ producer.ProducerCountry || '-' }}
                                    </span>
                                </td>
                                <td>
                                    <span class="font-semibold" [style.opacity]="mode === ddsModalModesEnum.AMEND ? '0.5' : '1'">
                                        {{ getTotalArea(producer.Areas) || '-' }}
                                    </span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <!-- End of Production Place -->
            </div>
        </div>
        <div *ngIf="editable" class="flex justify-content-end modal-action-section">
            <p-button class="mr-4 mt-4" [outlined]="true" (onClick)="closeModal()">
                {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.CANCEL" | transloco }}
            </p-button>
            <p-button *ngIf="mode === ddsModalModesEnum.SUBMIT" class="mr-4 mt-4" (onClick)="openConfirmationModal(ddsModalModesEnum.CONFIRM_SUBMISSION, false)"
                [disabled]="ddsFormGroup.invalid">
                {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.CREATE_DDS" | transloco }}
            </p-button>
            <p-button *ngIf="mode === ddsModalModesEnum.AMEND" class="mr-4 mt-4" (onClick)="ammendDDS()"
                
                >
                {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.SAVE_CHANGES" | transloco }}
            </p-button>
        </div>
        <div *ngIf="!editable" class="flex justify-content-end modal-action-section">
            <!-- <p-button class="mr-4 mt-4" [outlined]="true" (onClick)="changeModeToAmendDDS()">
                {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.AMEND" | transloco }}
            </p-button> -->
            <p-button class="mr-4 mt-4" [outlined]="true" (onClick)="closeModal()">
                {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.CLOSE" | transloco }}
            </p-button>
            <!-- <p-button class="mr-4 mt-4"
                (onClick)="openConfirmationModal(ddsModalModesEnum.WITHDRAW, false)">
                {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.WITHDRAW" | transloco }}</p-button> -->
        </div>
    </div>
</ng-container>

<ng-template #downloadInProgress>
    <div class="flex justify-content-center align-items-center" style="height: 35rem">
        <p-progressSpinner></p-progressSpinner>
    </div>
</ng-template>