import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, delay, map, of, tap } from 'rxjs';
import { PagedResponse } from 'src/app/models/paged-response.model';
import {
  PlantationDataset,
  LinkPlantationData,
  TableFilters,
  TraceabilityData,
  TradeConfirmationData,
  TransactionDataset,
  Plantation,
  UserInfo,
  SiSummary,
  PlantationGeometryData,
  Settings,
  PlantationRisk,
} from 'src/app/models/traceability-state.model';
import countryTableTestData from '../../test-data/country-table-test-data.json';
import siDetailsTestData from '../../test-data/si-details-test-data.json';
import plantationIdTestData from '../../test-data/plantation-id-table-test-data.json';
import { UtilityService } from '../utility.service';
import { CreateNewSiPayload } from 'src/app/models/create-new-si-payload.model';
import { EventStateService } from '../state-service/event-state.service';
import { PlantationListPayload } from 'src/app/models/plantation-list-payload.model';
import { TransactionsPagePayload } from 'src/app/models/transactions-page-payload.model';
import { LinkPlantationsToSiPayload } from 'src/app/models/link-plantations-to-si-payload.model';
import { environment } from 'src/environments/environment';
import { DownloadOptionsEnum } from 'src/app/enums/download-options.enum';
import { DdrDownloadPayload } from 'src/app/models/ddr-download.model';
import { PlantationRisksPayload } from 'src/app/models/plantation-risks-payload.model';
import { EmailToCounterpartyPayload } from 'src/app/models/email-to-counterparty.mode';
import { CompanyTypesEnum } from 'src/app/enums/company-types.enum';
import {
  PlantationTableFilters,
  TableParams,
} from 'src/app/models/table-params.model';
import { GeometryPayload } from 'src/app/models/geometry-payload.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private http: HttpClient,
    private utilityService: UtilityService,
    private datePipe: DatePipe,
    private eventStateService: EventStateService,
    private decimalPipe: DecimalPipe
  ) {}

  getSiPage(params: any): Observable<PagedResponse<TraceabilityData>> {
    const searchTerm = params.searchTerm
      ? '&si_number=' + params.searchTerm
      : '';
    const filter = params.filter ?? '';
    const ordering = params.ordering ?? '-created_at';

    return this.http
      .get<PagedResponse<TraceabilityData>>(
        `${environment.TRACEABILITY_API}si-page/?limit=${params.pageSize}&offset=${params.page}${searchTerm}&is_traceability_page=true&ordering=${ordering}${filter}`
      )
      .pipe(
        tap((res) => {
          if (!res?.results) {
            this.eventStateService.isSiSummariesLoaded = true;
          }
        }),
        map((res) => {
          if (Object.keys(res).length === 0) {
            // The response is an empty object
            return res;
          } else {
            const results = res.results!.map((result) => {
              const transformedDate = result.delivery_datetime
                ? this.utilityService
                    .getDateMonth(result.delivery_datetime)
                    ?.toString()
                : '';

              return {
                ...result,
                contract_number: result.contract_number,
                si_number: result.si_number,
                factory_code: result.factory_code,
                grade_name: result.grade_name,
                si_weight: result.si_weight,
                delivery_datetime: transformedDate,
                isExpanded: false,
                checked: false,
                disabled: false,
                polygon_data: null,
                extent_traceability: 'xxx',
                has_plantation_data: result?.has_plantation_data,
              };
            });
            return { ...res, results };
          }
        })
      );
  }

  getSiPageFilters(): Observable<TableFilters> {
    return this.http.get<TableFilters>(
      `${environment.TRACEABILITY_API}si-page/filter/`
    );
  }

  getCountryPage(): Observable<PagedResponse<TraceabilityData>> {
    return of(countryTableTestData).pipe(delay(3000));
  }

  getSiDetails(siNumber: string): Observable<TraceabilityData> {
    return of(siDetailsTestData).pipe(delay(3000));
  }

  getTradeConfirmationPage(
    params: any
  ): Observable<PagedResponse<TradeConfirmationData>> {
    const searchTerm = params.searchTerm ?? '';
    return this.http
      .get<PagedResponse<TradeConfirmationData>>(
        `${environment.ARP_API}contract/active/?contract_number=${searchTerm}&page=${params.page}&page_size=${params.pageSize}`
      )
      .pipe(
        map((res) => {
          const results = res.results!.map((result) => {
            const price = parseFloat(result.price).toFixed(2);
            const volume = parseFloat(result.volume).toFixed(2);

            const date = this.datePipe.transform(
              result.delivery_date,
              'MM/dd/yyyy'
            )!;

            return {
              contract_number: result.contract_number,
              id: result.id,
              type: result.type,
              price: price,
              volume: volume,
              factory: { factory_code: result.factory?.factory_code },
              delivery_date: date,
              grade: { grade_code: result.grade?.grade_code },
              producer: {
                id: result.producer?.id,
                name: result.producer?.name,
              },
              buyer: { name: result.buyer?.name },
              consumer: { name: result.consumer?.name },
            };
          });

          return { ...res, results };
        })
      );
  }

  getCounterparties(params: any): Observable<PagedResponse<any>> {
    return this.http.get<PagedResponse<any>>(
      `${environment.ARP_API}counterparties/?page=${params.page}&page_size=${params.pageSize}`
    );
  }

  getSiSummaries(siList: string[], siDetailList: number[]) {
    let params = '';

    if(siDetailList.length > 0) {
      params = this.utilityService.constructMultipleSiDetailQuery(siDetailList);
    } else {
      params = this.utilityService.constructMultipleSiQuery(siList);
    }
    return this.http
      .get<SiSummary[]>(
        `${environment.TRACEABILITY_API}si/summary/${params}`
      )
      .pipe(
        tap(() => {
          this.eventStateService.isSiSummariesLoaded = true;
        })
      );
  }

  getUserInfo(): Observable<UserInfo> {
    return this.http.get<UserInfo>(`${environment.ARP_API}user/info`);
  }

  createNewSi(payload: CreateNewSiPayload) {
    this.eventStateService.isCreatedNewSiLoading = true;
    this.http.post(`${environment.TRACEABILITY_API}si/`, payload).subscribe({
      next: (res) => {
        this.eventStateService.isCreatedNewSiLoading = false;
        this.eventStateService.isNewSiCreated = true;

        this.utilityService.emitToast({
          message: 'SI successfully created.',
          isSuccess: true,
        });
      },
      error: () => {
        this.eventStateService.isNewSiCreated = false;
        this.eventStateService.isCreatedNewSiLoading = false;
      },
    });
  }

  emailToCounterParty(payload: EmailToCounterpartyPayload) {
    //insert endpoint here
    return this.http
      .post(
        `${environment.TRACEABILITY_API}si/eudr-files/email/?si=${payload.si}&email=${payload.email}`,
        payload
      )
      .subscribe((res) => {
        this.utilityService.emitToast({
          message: 'Email sent successfully.',
          isSuccess: true,
        });
      });
  }

  getTransactionDatasets(
    factoryCode: string
  ): Observable<TransactionDataset[]> {
    return this.http
      .get<TransactionDataset[]>(
        `${environment.TRACEABILITY_INTEGRATIONS_API}get-factory-integrations?codes=${factoryCode}`
      )
      .pipe(
        map((datasets) => {
          const modifiedDatasets = [
            ...datasets.map((data) => ({
              label: data.factory_integration_provider.display_name,
              ...data,
            })),
          ];

          return modifiedDatasets;
        })
      );
  }

  getPlantationDatasets(): Observable<PlantationDataset[]> {
    return this.http
      .get<PlantationDataset[]>(`${environment.CRD_API}v1/datasets/`)
      .pipe(
        map((dataset) => {
          const mappedDataset = dataset.map((data) => ({
            ...data,
            label: data.description,
          }));
          return mappedDataset;
        })
      );
  }

  getPlantationRiskProperties(
    payload: PlantationRisksPayload
  ): Observable<PlantationRisk[]> {
    return this.http.get<PlantationRisk[]>(
      `${environment.CRD_API}v2/datasets/risks/?crd=${payload.crd}&plantation_table=${payload.plantationTable}`
    );
  }

  getPlantationsPaginated(
    plantationParams: PlantationListPayload,
    tableParams: TableParams,
    riskProperties: PlantationRisk[]
  ): Observable<PagedResponse<LinkPlantationData>> {
    const orderBy = tableParams.orderBy
      ? `&order_by=${tableParams.orderBy}`
      : '';
    const riskColumn = riskProperties.map(
      (prop) => `ifnull(${prop.name}_value, false) as ${prop.name}_value`
    );

    const columns = {
      columns: {
        schema_version: 'v1',
        columns: [
          'plantation_code',
          'plantation_name',
          'area_calculated',
          'country',
          'adm_1',
          'adm_2',
          'adm_3',
          'adm_4',
          'date_created',
          'data_source',
          'field_team_code',
          '_docid',
          'ifnull(has_any_risk_value, false) as has_any_risk_value',
          ...riskColumn,
        ],
      },
    };

    return this.http
      .post<PagedResponse<LinkPlantationData>>(
        `${environment.CRD_API}v2/plantations/?date=${plantationParams.period}&page=${tableParams.page}&page_size=${tableParams.pageSize}${orderBy}&crd=${plantationParams.crd}&plantation_table=${plantationParams.plantationTable}`,
        { ...columns, ...tableParams.filters }
      )
      .pipe(
        map((res) => {
          const results = res.results?.map((result) => {
            const formattedDateCreated = this.datePipe.transform(
              result.date_created,
              'MM/dd/yyyy h.mm a'
            )!;
            const formattedLandArea = this.decimalPipe.transform(
              result.area_calculated,
              '1.3-3'
            )!;

            return {
              ...result,
              date_created: formattedDateCreated,
              area_calculated: formattedLandArea,
              isExpanded: false,
              checked: false,
              disabled: false,
              risks: [],
            };
          });
          return { ...res, results };
        }),
        tap(() => {
          // this.eventStateService.isDashboardTableLoading = false;
        })
      );
  }

  getTransactionPage(
    payload: TransactionsPagePayload
  ): Observable<PagedResponse<LinkPlantationData>> {
    return this.http
      .get<PagedResponse<LinkPlantationData>>(
        `${environment.TRACEABILITY_INTEGRATIONS_API}${payload.provider}/factories/` +
          `transactions?pageNumber=${payload.page}` +
          `&pageSize=${payload.pageSize}&factoryCode=${payload.factoryCode}`
      )
      .pipe(
        map((res) => {
          const results = res.items!.map((result) => {
            const formattedTransactionDate = this.datePipe.transform(
              result.transactionDate,
              'MM/dd/yyyy'
            )!;

            const formattedDryWeight = this.decimalPipe.transform(
              result.dryWeightKg,
              '1.2-2'
            );

            return {
              ...result,
              transactionDate: formattedTransactionDate,
              dryWeightKg: formattedDryWeight!,
            };
          });

          return { ...res, items: results };
        })
      );
  }

  linkPlantationToSi(payload: LinkPlantationsToSiPayload): Observable<any> {
    return this.http
      .post(
        `${environment.TRACEABILITY_API}si/link-plantations-to-si/`,
        payload
      )
      .pipe(
        tap(() => {
          this.eventStateService.isLinkPlantationLoading = false;
          this.eventStateService.isPlantationLinked = true;
          this.utilityService.emitToast({
            message: 'Linked successfully.',
            isSuccess: true,
          });
        })
      );
  }

  downloadDdr(payload: DdrDownloadPayload) {
    return this.http.post<{ url: string }>(
      `${environment.TRACEABILITY_API}ddr/download/pdf/`,
      payload
    );
  }

  download(downloadOption: DownloadOptionsEnum, siList: string[], siDetailList: number[]) {
    let params;
    if(siDetailList.length > 0) {
      params = this.utilityService.constructMultipleSiDetailQuery(siDetailList);
    } else {
      params = this.utilityService.constructMultipleSiQuery(siList);
    }
    
    let url = '';
    switch (downloadOption) {
      case DownloadOptionsEnum.POLYGON_DATA_EUIS:
        url = 'plantations/download-euis';
        break;
      case DownloadOptionsEnum.FULL_RUSK_REPORT:
        url = 'plantations/download-risks';
        break;
      case DownloadOptionsEnum.EUDR_PACKAGE:
        url = 'download-eudr-package';
        break;
      default:
        break;
    }

    return this.http.get<Blob>(
      `${environment.TRACEABILITY_API}si/${url}/${params}`,
      {
        responseType: 'blob' as 'json',
        observe: 'response',
      }
    );
  }

  getGeoJsonData(si: string, sidetail_id: number) {
    return this.http
      .get<PlantationGeometryData[]>(
        `${environment.TRACEABILITY_API}si/plantations/download-euis/?si=${si}&sidetail_id=${sidetail_id}`
      );
  }

  getPlantationGeometryData(
    plantationCodes: string[],
    crd: string,
    plantationTable: string
  ): Observable<PlantationGeometryData[]> {
    let queryParams = `?crd=${crd}&plantation_table=${plantationTable}`;
    plantationCodes.forEach((code) => {
      queryParams += `&plantation_code=${code}`;
    });

    return this.http
      .get<PlantationGeometryData[]>(
        `${environment.CRD_API}v1/plantations/geometry/${queryParams}`
      )
      .pipe(
        tap(() => {
          this.eventStateService.isPlantationGeometryLoading = false;
        }),
        map((polygons) => {
          const parsedPolygons = polygons.map((polygon) => {
            return { ...polygon, _geo: JSON.parse(polygon._geo as string) };
          });
          return parsedPolygons;
        })
      );
  }

  getSettings(): Observable<Settings> {
    return this.http
      .get<Settings>(`${environment.TRACEABILITY_API}settings/`)
      .pipe(
        tap(() => {
          this.eventStateService.isSettingsLoaded = true;
        })
      );
  }

  // Create new SI counterparty - symbol and grade options
  getCounterpartyOptions(companyType: CompanyTypesEnum, companyId: number) {
    const type =
      companyType === CompanyTypesEnum.Producer
        ? 'bidding-panel'
        : 'offer-panel';
    return this.http.get(
      `${environment.ARP_API}${type}/filter-v2/?id=${companyId}`
    );
  }

  getEudrFiles(siNumber: string, siDetailId: number) {
    return this.http.get(
      `${environment.TRACEABILITY_API}si/eudr-package/files/?si=${encodeURIComponent(siNumber)}&sidetail_id=${siDetailId}`
    );
  }

  setEudrFiles(payload: any) {
    return this.http.patch<{ url: string }>(
      `${environment.TRACEABILITY_API}si/eudr-package/?si=${encodeURIComponent(payload.si)}&sidetail_id=${payload.sidetail_id}`,
      payload
    );
  }

  getGeometryData2(params: GeometryPayload, plantationParams: PlantationListPayload): Observable<any> {
    const body = {
      columns: {
        schema_version: 'v1',
        columns: [
          'ST_ASGEOJSON(IFNULL(risk_buffer_geo, _geo)) as _geo',
          'plantation_code',
        ],
      },
      AND: {
        plantation_code: params.plantationCodes,
      },
      OR: {},
    };

    return this.http
      .post<any>(
        `${environment.CRD_API}v2/plantations/?date=${params.period}&page=1&page_size=10000&crd=${plantationParams.crd}&plantation_table=${plantationParams.plantationTable}`,
        body
      )
      .pipe(
        map((geometry) => {
          const parsedGeometry = geometry.results.map((geo: any) => {
            return {
              ...geo,
              _geo: JSON.parse(geo._geo as string),
            };
          });

          this.eventStateService.isPlantationGeometryLoading = false;
          return parsedGeometry;
        })
      );
  }

  getPlantationListFilters(
    plantationParams: PlantationListPayload
  ): Observable<PlantationTableFilters> {
    return this.http
      .get(
        `${environment.CRD_API}v2/plantations/filters/?date=${plantationParams.period}&crd=${plantationParams.crd}&plantation_table=${plantationParams.plantationTable}`
      )
      .pipe(
        map((filters) => this.transformData(filters)),
        tap(() => (this.eventStateService.isPlantationFilterLoading = false))
      );
  }

  transformData(data: any): any {
    const transformed: any = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        transformed[key] = data[key].map((item: string) => ({
          label: item,
          value: item,
        }));
      }
    }

    return transformed;
  }
}
