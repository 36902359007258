import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { DDSModalModesEnum } from 'src/app/enums/traceability-table-tabs.enum';
import { DdsSubmissionConfirmationModalComponent } from './dds-submission-confirmation-modal/dds-submission-confirmation-modal.component';
import * as countries from 'countries-list';
import { UtilityService } from 'src/app/services/utility.service';
import { MessageService } from 'primeng/api';
import { distinctUntilChanged, map, Subject, takeUntil } from 'rxjs';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import { DashboardService } from 'src/app/services/data-service/dashboard.service';
import { EuisService } from 'src/app/services/data-service/euis.service';
import { GeoJson, GroupedData, Feature } from 'src/app/models/dds-submission-payload.model';

export const EU_COUNTRY_CODES: string[] = [
  'AT', 'BE', 'BG', 'HR', 'CY', 'CZ',
  'DK', 'EE', 'FI', 'FR', 'DE', 'GR',
  'HU', 'IE', 'IT', 'LV', 'LT', 'LU',
  'MT', 'NL', 'PL', 'PT', 'RO', 'SK',
  'SI', 'ES', 'SE',
];

@Component({
  selector: 'app-dds-submission-form-modal',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    TranslocoPipe,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './dds-submission-form-modal.component.html',
  styleUrls: ['./dds-submission-form-modal.component.scss']
})
export class DdsSubmissionFormModalComponent {
  rowData: any;
  editable: boolean = false;

  ddsFormGroup!: FormGroup;
  prefill = false;

  ddsModalModesEnum = DDSModalModesEnum;
  mode = DDSModalModesEnum.SUBMIT;

  activityOptions: any[] = [
    { label: 'Import', value: 'IMPORT' },
    { label: 'Export', value: 'EXPORT' }
  ];
  countryOptions: any[] = [];
  euCountryOptions: any[] = [];
  hsCodesOptions: any[] = [{}];
  companyName: string | undefined;
  destroyed$ = new Subject<void>();

  geoJsonData: any;
  groupedGeoData: GroupedData[] = [];

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    public eventStateService: EventStateService,
    private dialogService: DialogService,
    private translocoService: TranslocoService,
    private utilityService: UtilityService,
    private messageService: MessageService,
    private traceabilityStateService: TraceabilityStateService,
    private dashboardService: DashboardService,
    private euisService: EuisService
  ) { }

  ngOnInit() {
    this.getUserInfo();
    this.prefill = this.dialogConfig.data.prefill;
    this.editable = this.dialogConfig.data.editable;
    this.rowData = this.dialogConfig.data.rowData
    this.mode = this.dialogConfig.data.mode;
    console
    this.initializeCountryOptions();
    this.initializeFormGroup();
    this.initializeHsCodesOptions();
    this.retrieveGeoJsonData();
  }

  getUserInfo() {
    this.traceabilityStateService.userInfo$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((userInfo) => {
        this.companyName = userInfo?.company?.name || '';
      });
  }

  retrieveGeoJsonData() {
    this.dashboardService.getGeoJsonData(this.rowData.si_number, this.rowData.sidetail_id).subscribe((res: any) => {
      this.geoJsonData = res;
      this.groupedGeoData = this.groupGeoData(this.geoJsonData);
    });
  }

  groupGeoData(geoData: GeoJson): GroupedData[] {
    const grouped = geoData.features.reduce((accumulator: { [key: string]: GroupedData }, feature: Feature) => {
      const { ProducerCountry, ProducerName, Area } = feature.properties;
      const key = `${ProducerCountry}-${ProducerName}`;

      if (accumulator[key]) {
        accumulator[key].Areas.push(Area);
      } else {
        accumulator[key] = {
          ProducerCountry,
          ProducerName,
          Areas: [Area]
        };
      }

      return accumulator;
    }, {});

    return Object.values(grouped);
  }

  getTotalArea(areas: number[]): number {
    return parseFloat(areas.reduce((acc, area) => acc + area, 0).toFixed(2));
  }

  initializeCountryOptions() {
    this.countryOptions = Object.values(countries.countries)
      .map((country: any) => {
        const countryCode = countries.getCountryCode(country.name);
        return { label: country.name, value: country.name, code: countryCode };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    this.euCountryOptions = Object.values(countries.countries)
      .filter((country: any) => {
        const countryCode = countries.getCountryCode(country.name);
        return typeof countryCode === 'string' && EU_COUNTRY_CODES.includes(countryCode);
      })
      .map((country: any) => {
        const countryCode = countries.getCountryCode(country.name);
        return { label: country.name, value: country.name, code: countryCode };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  initializeHsCodesOptions() {
    this.euisService.getHsCodes().pipe(
      map(hsCodes => hsCodes.map((code: any) => ({
        label: `${code.hscode}: ${code.description}`,
        value: code.hscode
      })))
    ).subscribe({
      next: (mappedHsCodes) => {
        this.hsCodesOptions = mappedHsCodes;
        if (this.hsCodesOptions.length > 0) {
          this.ddsFormGroup.get('hsCode')?.setValue(this.hsCodesOptions[0].value);
        }
      },
      error: (error) => {
        console.error('Error loading HS codes:', error);
      }
    });
  }

  initializeFormGroup() {
    this.ddsFormGroup = this.dialogConfig.data.form ? this.dialogConfig.data.form : new FormGroup({
      prefill: new FormControl(this.prefill),
      companyInternalRefNo: new FormControl(''),
      activity: new FormControl('IMPORT', Validators.required),
      operatorName: new FormControl(this.companyName, Validators.required),
      operatorCountry: new FormControl('', Validators.required),
      operatorISOCode: new FormControl('', Validators.required),
      activityCountry: new FormControl(''),
      entryCountry: new FormControl(''),
      exitCountry: new FormControl(''),
      additionalInfo: new FormControl(''),
      hsCode: new FormControl(this.hsCodesOptions.length > 0 ? this.hsCodesOptions[0].value : ''),
      goodsDescription: new FormControl('', Validators.required),
      scientificName: new FormControl(''),
      commonName: new FormControl(''),
      netMass: new FormControl(this.rowData.si_weight * 1000, Validators.required),
      volume: new FormControl(''),
      supplementaryUnits: new FormControl(''),
      area: new FormControl(
        this.rowData.summary.error === null ? this.rowData.summary.total_area : ''
      ),
      producerName: new FormControl(this.rowData.producer, Validators.required),
      productionCountry: new FormControl(this.rowData.summary.error === null ? this.getCountryValue(this.rowData.summary.countries[0]) : this.countryOptions[0].value),
      productionTotalArea: new FormControl(this.rowData.summary.error === null ? this.rowData.summary.total_area : '')
    });

    this.ddsFormGroup.get('activity')?.valueChanges.pipe(
      distinctUntilChanged()
    ).subscribe((activity) => {
      this.handleActivityChange(activity);
    });
  }

  getCountryValue(countryFromData: string): string {
    const matchedCountry = this.countryOptions.find(
      option => option.value === countryFromData || option.label.toLowerCase() === countryFromData.toLowerCase()
    );

    return matchedCountry ? matchedCountry.value : this.countryOptions[0].value;
  }

  get activity() {
    return this.ddsFormGroup.get('activity')?.value;
  }

  handleActivityChange(activity: string) {
    const exitCountryControl = this.ddsFormGroup.get('exitCountry');
    const activityCountryControl = this.ddsFormGroup.get('activityCountry');
    const entryCountryControl = this.ddsFormGroup.get('entryCountry');
    exitCountryControl?.setValue('');
    activityCountryControl?.setValue('');
    entryCountryControl?.setValue('');

    if (activity === 'EXPORT') {
      activityCountryControl?.clearValidators();
      entryCountryControl?.clearValidators();
      exitCountryControl?.setValidators(Validators.required);
    } else if (activity === 'IMPORT') {
      exitCountryControl?.clearValidators();
      activityCountryControl?.setValidators(Validators.required);
      entryCountryControl?.setValidators(Validators.required);
    }
    exitCountryControl?.updateValueAndValidity();
    activityCountryControl?.updateValueAndValidity();
    entryCountryControl?.updateValueAndValidity();
  }

  onCountryChange(event: any) {
    const selectedCountry = event.value;
    const selectedCountryData = this.countryOptions.find(country => country.value === selectedCountry);

    if (selectedCountryData) {
      this.ddsFormGroup.get('operatorISOCode')?.setValue(selectedCountryData.code);
    }
  }

  copyOperatorCountry() {
    if (this.activity === 'IMPORT') {
      this.ddsFormGroup.get('activityCountry')?.setValue(this.ddsFormGroup.get('operatorCountry')?.value);
      this.ddsFormGroup.get('entryCountry')?.setValue(this.ddsFormGroup.get('operatorCountry')?.value);
    } else if (this.activity === 'EXPORT') {
      this.ddsFormGroup.get('exitCountry')?.setValue(this.ddsFormGroup.get('operatorCountry')?.value);
    }
  }

  openConfirmationModal(mode: DDSModalModesEnum, editable: boolean) {
    this.closeModal();
    this.dialogService.open(DdsSubmissionConfirmationModalComponent, {
      width: '60%',
      header: this.translocoService.translate(
        'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.DDS_CREATE_SUBMISSION_FORM_HEADER'
      ),
      data: { form: this.ddsFormGroup, editable, mode, rowData: this.rowData, geoJsonData: this.geoJsonData, tableParams: this.dialogConfig.data.tableParams },
    });
  }

  changeModeToAmendDDS() {
    this.editable = true;
    this.mode = this.ddsModalModesEnum.AMEND;
  }

  closeModal() {
    this.dialogRef.close();
  }

  ammendDDS() {
    //insert backend integration of updating DDS
    this.closeModal();
    this.messageService.add({
      severity: 'success',
      detail: 'DDS submission for SI/0123/0010 has been ammended successfully'
    });
  }
}
